import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

import Quote from './quote'
import Cite from './cite'
import { isLowLuminanceColor } from '../utils'

type Props = {
  content: string
  caption?: string
  hasBackgroundColor: boolean
}

const QuoteContent: React.FC<Props> = ({
  content,
  caption,
  hasBackgroundColor,
}) => {
  const themeContext = useContext(ThemeContext)

  const lightTheme = hasBackgroundColor
    ? isLowLuminanceColor(themeContext.colors.primary)
    : false

  return (
    <Wrapper hasBackgroundColor={hasBackgroundColor}>
      <Container
        hasBackgroundColor={hasBackgroundColor}
        lightTheme={lightTheme}
      >
        <Quote>{content}</Quote>
        <Cite>{caption}</Cite>
      </Container>
    </Wrapper>
  )
}

export default QuoteContent

type InjectedWrapperProps = {
  hasBackgroundColor: boolean
}

type InjectedContainerProps = {
  lightTheme: boolean
  hasBackgroundColor: boolean
}

const Wrapper = styled.div<InjectedWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${(props) =>
    props.hasBackgroundColor && props.theme.colors.primary};

  ${Quote} + ${Cite} {
    margin-top: 1rem;
  }
`

const Container = styled.div<InjectedContainerProps>`
  max-width: 60%;
  padding: calc(5.85 / ${(props) => (props.hasBackgroundColor ? 1 : 2)} * 1rem)
    0;
  color: ${(props) =>
    props.lightTheme ? 'var(--white-color)' : 'var(--grey-color)'};

  ${TABLET_MEDIA_QUERY} {
    max-width: none;
    padding: calc(4 / ${(props) => (props.hasBackgroundColor ? 1 : 2)} * 1rem)
      2.275rem;
  }

  ${MOBILE_MEDIA_QUERY} {
    text-align: ${(props) => (props.hasBackgroundColor ? 'center' : 'left')};
    padding: calc(4 / ${(props) => (props.hasBackgroundColor ? 1 : 4)} * 1rem)
      2.275rem;
  }
`
