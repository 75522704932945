import React, { useRef, useEffect, useContext } from 'react'
import styled, { ThemeContext, withTheme } from 'styled-components'
import Vimeo from '@vimeo/player'

type Props = {
  url: string
  theme: any
}

const VideoContent: React.FC<Props> = ({ url }) => {
  const ref = useRef<HTMLDivElement>(null)
  const context = useContext(ThemeContext)

  useEffect(() => {
    let player: Vimeo

    if (ref.current) {
      player = new Vimeo(ref.current, {
        url: url,
        color: context.colors.primary.substr(1),
        autoplay: false,
        background: true,
        loop: true,
      })
    }

    return () => {
      if (player) {
        player.destroy()
      }
    }
  }, [url])

  return (
    <Container>
      <Player ref={ref} aria-label="video player"></Player>
    </Container>
  )
}

export default withTheme(VideoContent)

const Container = styled.div`
  position: relative;
  padding-bottom: ${(9 / 16) * 100}%;
  overflow: hidden;

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`

const Player = styled.div`
  iframe {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`
