import React from 'react'
import styled from 'styled-components'
import { TABLET_MEDIA_QUERY } from 'typography-breakpoint-constants'

import { ContentLayout, ContentPosition } from '../@types/core'

import Text from './text'

type Props = {
  title?: string
  content: string
  layout: ContentLayout.Column | ContentLayout.Wide
  position?: ContentPosition.Left | ContentPosition.Right
}

const TextContent: React.FC<Props> = ({ title, content, layout }) => {
  const HR_TAG = '<hr />'
  const isColumned = layout === ContentLayout.Wide
  let items = isColumned ? content.split(HR_TAG) : [content.replace(HR_TAG, '')]

  if (items.length > 2) {
    const [first, ...rest] = items
    items = [first, rest.join(' ')]
  }

  return (
    <>
      {title && <h3>{title}</h3>}
      <Wrapper>
        {items.map((item, index) => (
          <Container key={index}>
            <Text content={item} />
          </Container>
        ))}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  column-gap: calc(
    (100% / (var(--grid-column-count) - 2) + var(--grid-column-gap)) * 2
  );

  ${TABLET_MEDIA_QUERY} {
    flex-direction: column;
  }
`

const Container = styled.div`
  flex-basis: 100%;
  flex: 1;
`

export default TextContent
