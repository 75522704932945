import React from 'react'
import styled, { css } from 'styled-components'
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

import {
  Content,
  TextContent,
  QuoteContent,
  ImageContent,
  VideoContent,
  ContentType,
  ContentLayout,
  ContentPosition,
} from '../@types/core'

import Quote from './quote-content'
import Text from './text-content'
import Video from './video-content'
import Image from './image-content'

type Props = {
  content: Content
}

const ContentItem: React.FC<Props> = ({ content }): JSX.Element => {
  let inner: JSX.Element | null = null

  if (isTextContent(content)) {
    inner = (
      <Text
        title={content.title}
        content={content.content}
        layout={content.layout}
        position={content.position}
      />
    )
  } else if (isQuoteContent(content)) {
    inner = (
      <Quote
        content={content.content}
        caption={content.caption}
        hasBackgroundColor={content.hasBackgroundColor}
      />
    )
  } else if (isImageContent(content)) {
    inner = (
      <Image
        position={content.position}
        media={content.image}
        hasDescription={content.hasDescription}
      />
    )
  } else if (isVideoContent(content)) {
    inner = <Video url={content.video} />
  }

  return (
    <Container
      key={content.id}
      layout={content.layout}
      type={content.type}
      position={
        isTextContent(content) || isImageContent(content)
          ? content.position
          : undefined
      }
    >
      {inner}
    </Container>
  )
}

export default ContentItem

type InjectProps = {
  layout: ContentLayout
  position?: ContentPosition
  type?: ContentType
}

const Container = styled.div<InjectProps>`
  position: relative;

  ${(props) =>
    props.layout === ContentLayout.Fullscreen &&
    css`
      grid-column: 1 / -1;
    `}

  ${(props) =>
    props.layout === ContentLayout.Wide &&
    css`
      grid-column: 2 / -2;
    `}
    

  ${(props) =>
    props.layout === ContentLayout.Wide &&
    props.type === ContentType.Text &&
    css`
      grid-column: 3 / -3;
    `}
    
  ${(props) =>
    props.layout === ContentLayout.Column &&
    props.position === ContentPosition.Left &&
    css`
      grid-column: 3 / 12;
    `}
  
  ${(props) =>
    props.layout === ContentLayout.Column &&
    props.position === ContentPosition.Right &&
    css`
      grid-column: 14 / -3;
    `};

  ${TABLET_MEDIA_QUERY} {
    ${(props) =>
      props.type === ContentType.Text
        ? css`
            grid-column: 5 / -5;
          `
        : props.type === ContentType.Quote
        ? css`
            grid-column: 3 / -3;
          `
        : css`
            grid-column: 2 / -2;
          `}
  }

  ${MOBILE_MEDIA_QUERY} {
    ${(props) =>
      props.type === ContentType.Text
        ? css`
            grid-column: 3 / -3;
          `
        : props.type === ContentType.Quote
        ? css`
            grid-column: 3 / -3;
          `
        : css`
            grid-column: 1 / -1;
          `}
  }
`

function isTextContent(content: Content): content is TextContent {
  return content.type === ContentType.Text
}

function isQuoteContent(content: Content): content is QuoteContent {
  return content.type === ContentType.Quote
}

function isImageContent(content: Content): content is ImageContent {
  return content.type === ContentType.Image
}

function isVideoContent(content: Content): content is VideoContent {
  return content.type === ContentType.Video
}
