import styled from 'styled-components'

const Cite = styled.cite`
  display: block;
  font-style: normal;
  font-size: 0.55rem;
  font-weight: 500;
  letter-spacing: 0.04rem;
  text-transform: uppercase;

  > * {
    margin: 0;
  }
`

export default Cite
