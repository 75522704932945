import React from 'react'
import styled from 'styled-components'
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'
import SafeHTML from './safe-html'

type Props = {
  content: string
}

const Text: React.FC<Props> = ({ content }) => (
  <StyledSafeHTML content={content} />
)

const StyledSafeHTML = styled(SafeHTML)`
  p + p {
    margin-top: 2rem;
    margin-bottom: 0;
  }

  strong,
  blockquote {
    font-weight: 500;
  }

  strong {
    display: block;
    margin-bottom: 2.8rem;
    font-size: 1.8rem;
    line-height: 2.05rem;
  }

  blockquote {
    font-style: italic;
    line-height: 2.05rem;
    font-size: 1.45rem;
    margin: 2.8rem 0 2.8rem -1.58rem;

    &::before {
      content: '«';
      margin-right: 0.25em;
    }
    &::after {
      content: '»';
      margin-left: 0.25em;
    }
    p {
      display: inline;
    }
  }

  > *:last-child {
    margin-bottom: 0;
  }

  ${TABLET_MEDIA_QUERY} {
    strong {
      margin-bottom: 2.3rem;
      font-size: 1.6rem;
      line-height: 2.05rem;
    }

    blockquote {
      margin: 2.6rem -2rem;
    }
  }

  ${MOBILE_MEDIA_QUERY} {
    blockquote {
      margin: 2.6rem -1rem;
    }
  }
`

export default Text
