import React, { useRef, useState, useEffect, useCallback } from 'react'
import { animated, useSpring, config, to } from 'react-spring'
// @ts-expect-error: No types available
import Link from 'gatsby-plugin-transition-link'
import styled from 'styled-components'

const BackButton: React.FC = () => {
  const timer = useRef<number>()
  const [isHover, setIsHover] = useState<boolean>(true)

  const { progress } = useSpring({
    progress: isHover ? 1 : 0,
    config: { ...config.default, duration: 150 },
  })

  const cb = useCallback(
    (state: boolean) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }

      setIsHover(state)
    },
    [setIsHover]
  )

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setIsHover(false)
    }, 2000)

    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  return (
    <Container
      aria-label="back"
      to={'/'}
      onMouseEnter={() => cb(true)}
      onMouseLeave={() => cb(false)}
    >
      <Bar
        // @ts-expect-error: react-spring: Type instantiation is excessively deep and possibly infinite
        style={{
          top: progress.to({ range: [0, 1], output: ['50%', '0%'] }),
          transform: progress.to({
            range: [0, 1],
            output: ['rotate(45deg)', 'rotate(0deg)'],
          }),
        }}
      />
      <Bar
        style={{
          bottom: progress.to({ range: [0, 1], output: ['50%', '0%'] }),
          transform: progress.to({
            range: [0, 1],
            output: ['rotate(-45deg)', 'rotate(0deg)'],
          }),
        }}
      />
      <Content style={{ opacity: to([progress], (progress) => progress) }}>
        BACK
      </Content>
    </Container>
  )
}

export default BackButton

const Content = styled(animated.span)`
  font-size: 0.8em;
  line-height: 70px;
  color: var(--dark-grey-color);
  font-weight: 700;
  will-change: opacity;
`

const Bar = styled(animated.span)`
  position: absolute;
  content: '';
  height: 2px;
  width: inherit;
  background-color: var(--dark-grey-color);
  left: 0;
  transition: all 200ms ease-in;
  will-change: transform;
`

const Container = styled(Link)`
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
  text-align: center;
  text-decoration: none;
`
