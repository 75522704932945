import styled from 'styled-components'
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

const Quote = styled.q`
  display: block;
  font-size: 2.9rem;
  font-style: italic;
  font-weight: 500;
  line-height: 3.17rem;

  :not(:only-child) {
    margin-bottom: 2rem;
  }

  ${TABLET_MEDIA_QUERY} {
    font-style: normal;
    font-size: 2.1rem;
    line-height: 2.97rem;
  }

  ${MOBILE_MEDIA_QUERY} {
    font-size: 1.5rem;
    line-height: 2.27rem;

    :not(:only-child) {
      margin-bottom: 1rem;
    }
  }
`

export default Quote
