import React from 'react'
import { graphql } from 'gatsby'
import { Movie } from 'schema-dts'
import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import styled, { ThemeProvider, css } from 'styled-components'
import {
  TABLET_MEDIA_QUERY,
  MOBILE_MEDIA_QUERY,
} from 'typography-breakpoint-constants'

import Text from '../components/text'
import Preview from '../components/preview'
import Order from '../components/order'
import Image from '../components/image'
import Layout from '../components/layout'
import ContentItem from '../components/content-item'
import NestedList from '../components/nested-list'
import BackButton from '../components/back-button'
import { Project, Property } from '../@types/core'
import { projectMapper } from '../mapper'
import InfoBadge from '../assets/info-badge.png'
import IconArrow from '../assets/svg/icon-arrow.svg'
import { renderMovieJsonLdProp, pluralize } from '../utils'

interface Props {
  location: Location
  data: GatsbyTypes.ProjectQueryQuery
}

const Wrapper = styled.article``

const Section = styled.section`
  overflow: hidden;
  display: grid;
  position: relative;
  grid-template-columns:
    minmax(var(--grid-column-gap), 1fr)
    repeat(
      calc(var(--grid-column-count) - 2),
      minmax(
        auto,
        calc(1440px / var(--grid-column-count) - var(--grid-column-gap))
      )
    )
    minmax(var(--grid-column-gap), 1fr);
  grid-column-gap: var(--grid-column-gap);
  row-gap: 120px;
  align-items: flex-start;
  padding-top: 5rem;

  ${TABLET_MEDIA_QUERY} {
    row-gap: 70px;
  }
`

const Line = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: var(--grid-column-gap);
  background: ${(props) => props.theme.colors.primary};
  z-index: 1;

  ${MOBILE_MEDIA_QUERY} {
    width: 100%;
    height: var(--grid-column-gap);
  }
`

const Header = styled.header`
  grid-column: 2 / -7;
  order: -10;

  ${TABLET_MEDIA_QUERY} {
    grid-column: 3 / -7;
  }

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 3 / 18;
  }
`

const StyledOrder = styled(Order)`
  display: block;
  line-height: 1.34rem;
  font-size: 1rem;
`

const Title = styled.h1`
  display: inline-block;
  font-size: 4.22rem;
  margin: 0 0 0 -5px;

  ${TABLET_MEDIA_QUERY} {
    font-size: 2.66rem;
  }

  ${MOBILE_MEDIA_QUERY} {
    font-size: 2.06rem;
  }
`

const BackButtonWrapper = styled.div`
  grid-column: 22/24;
  width: 64px;
  justify-self: flex-end;
  order: -10;

  ${TABLET_MEDIA_QUERY} {
    grid-column: 19 / 24;
    width: 64px;
  }

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 18 / 23;
    width: 54px;
  }
`

const Hr = styled.hr`
  grid-column: 1/-1;
  background-color: var(--medium-grey-color);
  height: 1px;
  margin: 0;
  order: -9;

  ${MOBILE_MEDIA_QUERY} {
    display: none;
  }
`

const PropertiesWrapper = styled.div`
  position: relative;
  grid-column: 2 / 5;
  order: -8;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 160px;
    height: 160px;
    transform: translate(-130px, -120px);
    background-image: url(${InfoBadge});

    ${TABLET_MEDIA_QUERY} {
      display: none;
    }
  }

  ${TABLET_MEDIA_QUERY} {
    grid-column: 3 / 7;
  }

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 3 / -3;
  }
`

const StyledIconArrow = styled(IconArrow)`
  display: block;
`

const PropertiesContainer = styled.div`
  padding-bottom: 70px;
  margin: 0 0 50px;
  border-bottom: 1px solid var(--medium-grey-color);

  ${TABLET_MEDIA_QUERY} {
    padding-bottom: 30px;
  }

  ${StyledIconArrow} {
    margin-top: 50px;
  }
`

const ImageWrapper = styled.div`
  grid-column: 7 / 15;
  order: -7;

  ${TABLET_MEDIA_QUERY} {
    grid-column: 10 / 23;
  }

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 1 / -1;
  }
`

const Description = styled.div`
  grid-column: 17 / -2;
  order: -6;

  ${TABLET_MEDIA_QUERY} {
    grid-column: 5 / -5;
  }
  ${MOBILE_MEDIA_QUERY} {
    grid-column: 3 / -3;
  }
`

const Hightlight = styled.div`
  font-size: 23.2vw;
  font-weight: 500;
  line-height: 17.3vw;
  text-indent: -2rem;
  white-space: nowrap;

  &:not(:only-child) {
    margin-bottom: 1.33rem;
  }

  ${(props) =>
    props.theme.colors.primary &&
    css`
      color: ${props.theme.colors.primary};
    `};

  ${TABLET_MEDIA_QUERY} {
    display: none;
  }
`

const Navigation = styled.nav`
  display: flex;
  width: 100%;
  margin-top: 120px;
  position: relative;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--black-color);
  z-index: 2;

  ${TABLET_MEDIA_QUERY} {
    margin-top: 70px;
  }

  ${MOBILE_MEDIA_QUERY} {
    flex-direction: column;
  }
`

const Next = styled.div`
  padding: 4rem 0;

  ${StyledIconArrow} {
    margin: 3rem 0 0;
    transform: rotate(-90deg);
    fill: var(--white-color);

    ${MOBILE_MEDIA_QUERY} {
      transform: rotate(0deg);
    }
  }
`

const PreviewWrapper = styled.div`
  width: 360px;
  padding: 35px 27px;
  background-color: var(--white-color);

  ${MOBILE_MEDIA_QUERY} {
    height: calc(100vh - 2rem);
    width: calc(100% - 2rem);
    margin: 0 1rem 1rem;
  }
`

const Span = styled.span`
  color: var(--white-color);
  font-size: 6.6rem;
  font-weight: 500;
  line-height: 6.5rem;
  white-space: pre;
  word-break: break-all;
  text-decoration: none;

  ${TABLET_MEDIA_QUERY} {
    font-size: 4.6rem;
    line-height: 4.5rem;
  }
`

const ProjectTemplate: React.FC<Props> = ({ location, data }) => {
  if (!data.project) {
    throw new Error(`Invalid project for path ${location.pathname}`)
  }

  // @ts-expect-error: FFS read-only
  const project = projectMapper(data.project, data.projects?.acf?.projects)

  return (
    <Layout location={location} seo={data?.project?.seo}>
      <Helmet
        script={[
          helmetJsonLdProp<Movie>({
            '@context': 'https://schema.org',
            ...renderMovieJsonLdProp(project),
          }),
        ]}
      />

      <ThemeProvider theme={{ colors: { primary: project.color } }}>
        <Wrapper id={project.slug}>
          <Line aria-hidden />

          <Section aria-label="General">
            <Header>
              {project.order !== 0 && <StyledOrder order={project.order} />}

              {project.title && <Title>{project.title}</Title>}
            </Header>

            <BackButtonWrapper>
              <BackButton />
            </BackButtonWrapper>

            <Hr />

            {project.properties.length && (
              <PropertiesWrapper>
                <PropertiesContainer>
                  <NestedList items={constructProperties(project)} />
                </PropertiesContainer>

                <StyledIconArrow />
              </PropertiesWrapper>
            )}

            {project.thumbnail && (
              <ImageWrapper>
                <Image
                  fluid={project.thumbnail.fluid}
                  title={project.thumbnail.title}
                />
              </ImageWrapper>
            )}

            <Description>
              {project.title && (
                <Hightlight aria-hidden>{project.title}</Hightlight>
              )}

              {project.description && <Text content={project.description} />}
            </Description>
          </Section>

          <Section aria-label="Content">
            {project.content.map((content) => (
              <ContentItem key={content.id} content={content} />
            ))}
          </Section>

          {project.next && (
            <Navigation aria-label="Next project">
              <Next>
                <Span>
                  Next
                  {'\n'}
                  project
                </Span>

                <StyledIconArrow />
              </Next>

              <PreviewWrapper>
                <Preview
                  project={project.next}
                  index={project.next.order}
                ></Preview>
              </PreviewWrapper>
            </Navigation>
          )}
        </Wrapper>
      </ThemeProvider>
    </Layout>
  )
}

function constructProperties(project: Project): Property[] {
  return [
    ...(project.roles.length
      ? [
          {
            name: 'roles',
            values: project.roles,
          },
        ]
      : []),
    ...(project.directors.length
      ? [
          {
            name: pluralize('réalisateur', project.directors.length),
            values: project.directors,
          },
        ]
      : []),
    ...(project.authors.length
      ? [
          {
            name: pluralize('auteur', project.authors.length),
            values: project.authors,
          },
        ]
      : []),
    {
      name: 'année',
      values: new Date(project.date).getFullYear().toString(),
    },
    ...project.properties,
  ]
}

export const ProjectQuery = graphql`
  query ProjectQuery($id: String!) {
    project: wpPost(id: { eq: $id }) {
      seo {
        ...WpSEOFragment
      }
      ...ProjectFields
    }
    projects: wpPage(isFrontPage: { eq: true }) {
      acf {
        projects {
          ...BaseProjectFields
        }
      }
    }
  }
`

export default ProjectTemplate
