import React from 'react'
import styled, { css } from 'styled-components'
import { TABLET_MEDIA_QUERY } from 'typography-breakpoint-constants'

import Image from './image'
import Quote from './quote'
import Cite from './cite'

import { ContentPosition, Image as ImageType } from '../@types/core'

type Props = {
  position: ContentPosition
  media: ImageType
  hasDescription: boolean
  projectLetters?: string
}

const ImageContent: React.FC<Props> = ({ position, media, hasDescription }) => (
  <Figure
    reversed={position === ContentPosition.Left}
    hasDescription={hasDescription}
  >
    {hasDescription && (
      <Figcaption>
        {media.description && (
          <StyledQuote
            dangerouslySetInnerHTML={{ __html: media.description }}
          />
        )}
        {media.caption && (
          <Cite dangerouslySetInnerHTML={{ __html: media.caption }} />
        )}
      </Figcaption>
    )}

    {media.fluid && <StyledImage title={media.title} fluid={media.fluid} />}
  </Figure>
)

export default ImageContent

type InjectedFigureProps = {
  reversed: boolean
  hasDescription: boolean
}

const Figure = styled.figure<InjectedFigureProps>`
  position: relative;
  display: flex;
  margin: 0;
  align-items: center;

  ${(props) =>
    props.reversed
      ? css`
          flex-direction: row-reverse;
        `
      : css`
          flex-direction: row;
        `}

  ${(props) =>
    props.hasDescription
      ? css`
          justify-content: space-between;
        `
      : css`
          justify-content: center;
        `}


  ${TABLET_MEDIA_QUERY} {
    flex-direction: column-reverse;
  }
`

const Figcaption = styled.figcaption`
  position: relative;
  flex: 0 1 40%;
  padding: 4rem 0;
  margin: 0 calc(1 / 24 * 100vw) 0;
  text-align: left;
  align-self: center;
  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    height: 1px;
    background-color: var(--black-color);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  ${TABLET_MEDIA_QUERY} {
    margin: 80px 0 35px;
    padding: 2rem;
  }
`

const StyledQuote = styled(Quote)`
  font-style: normal;
  &:before,
  &:after {
    content: '';
  }
`

const StyledImage = styled(Image)`
  flex: 1 1 100%;
  &:not(:only-child) {
    flex: 1 1 40%;

    ${TABLET_MEDIA_QUERY} {
      width: 100%;
    }
  }
`
